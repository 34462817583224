import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Gallery from '@browniebroke/gatsby-image-gallery';

import Layout from "../templates/Layout";


export const pageQuery = graphql`
    query Allotments {
        mdx(frontmatter: {title: {eq: "Allotments"}}) {
            body
            frontmatter {
              title
            }
        }

        allFile (
            filter: {
                relativeDirectory: {eq: "allotment"}
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        thumb: gatsbyImageData(
                            width: 270
                            height: 270
                            placeholder: BLURRED
                        )
                        full: gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`


class Allotments extends React.Component {
    render() {
        const data = this.props.data.mdx;
        const images = this.props.data.allFile.edges.map(({ node }) => node.childImageSharp)

        return (
            <Layout
                title={data.frontmatter.title}
                location={this.props.location}
            >
                <MDXRenderer>{data.body}</MDXRenderer>

                <h3>Photos</h3>
                <Gallery images={images} />
            </Layout>
        )
    }
}

export default Allotments